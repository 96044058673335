import React, { useState, useEffect } from 'react'
import { Table, TableBody, TableCell, TableHead, TableContainer, TableRow, Paper } from '@material-ui/core'
import { axiosInstance } from '../utils/AxiosUtils'

export default function Dashboard() {
    const [data, setData] = useState([])

    const getData = () => {
        axiosInstance.get('test', { params: { host: window.location.host } })
            .then(response => {
                setData(response.data)
            })
    }
    console.log(window.location.host)
    useEffect(() => {
        getData()
    }, [])

    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data.map(row => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    {row.id}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}