import axios from 'axios'

//internal
import { BaseURL } from '../globals/Routes'

const config = {
    baseURL: BaseURL,
    headers: {
        'Content-Type': 'application/json'
     }
}

export const axiosInstance = axios.create(config)



