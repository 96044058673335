import React from 'react'

//internal
import Dashboard from './containers/Dashboard'

function App() {
  return (
    <React.Fragment>
      <Dashboard />
    </React.Fragment>
  )
}

export default App
